<template>
    <div>
        <div class="header">
            <van-nav-bar :title="$t('hq.news')" left-arrow @click-left="$router.go(-1)" />
        </div>
        <div class="maincontent">
            <van-image :src="news.image" style="width: 100%;" />
            <p class="text" v-html="news.content"></p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            news: {}
        };
    },
    created() {
        this.getnews();
    },
    methods: {
        // 获取新闻信息
        async getnews() {
            const { data } = await this.$http.get(
                '/home/news/newsdetail/id/' + this.$route.params.id
            );
            if (data) {
                if (data.code === 200) {
                    this.news = data.data;
                } else {
                    this.$toast.fail(this.$t('common.fail'));
                }
            }
        }
    }
};
</script>
<style lang="less" scoped>
.maincontent {
    padding-top: 55px;
    flex: 1;
    font-size: 0.93333rem;
    background: #f7f7f7;
    // height: 100vh;
    padding: 60px 5px 10px;
    overflow: hidden;

    figure {
        width: 100%;
        width: 100vw;

    }

    img {
            width: 100vw;
            height: 50vw;
            object-fit: cover;
        }
}
</style>
